import React from 'react'
import Img from "gatsby-image"
import tw from 'twin.macro';
import PropTypes from 'prop-types';

import Container from './Container'

const HeroWrapper = tw.dev`flex items-center justify-center text-gray-900 relative`

const Hero = props => {
    return (
        <HeroWrapper {...props}>
            <div tw="overflow-hidden h-full w-full absolute">
                {props.background ?
                    <Img fixed={props.background} style={{ position: 'absolute' }} css={[tw`min-w-full min-h-full max-w-full max-h-full transform -translate-x-1/2 -translate-y-1/2`, 'z-index: -1; top:50%;left:50%;']} />
                : null}
            </div>
            <Container tw="z-20">
                {props.children}
            </Container>
        </HeroWrapper>
    )
}

Hero.propTypes = ({
    children: PropTypes.node.isRequired,
})

export default Hero