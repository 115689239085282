import React from 'react'
import tw from 'twin.macro'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Home from '../components/Home'

import { graphql } from 'gatsby'
import Container from '../components/Container'
import Testimonials from '../components/Testimonials'

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO />
      <Home.Hero data={data.page.hero} />
      <Container>
        <div tw="grid grid-cols-1 row-gap-16 lg:(grid-cols-3 col-gap-16)">
          <Home.Services data={data.page.services} css={[tw`col-span-2`]} />
          <Home.Blog data={data.blog} />
          <Home.Difference data={data.page.difference} css={[tw`col-span-2`]} />
          <Home.Satisfaction data={data.page.satisfaction} />
        </div>
        <Testimonials.Carousel />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    page: wpPage(isFrontPage: { eq: true }) {
      hero: homeHero {
        crown
        headline
        subtext
        cta {
          title
          url
          target
        }
        backgroundImage {
          localFile {
            childImageSharp {
              fixed(width: 1920, height: 1080) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
      services: homeServices {
        headline: servicesHeadline
        paragraph: servicesParagraph
        services {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          headline
          link {
            url
          }
        }
      }
      difference: homeDifference {
        headline: differenceHeadline
        paragraph: differenceParagraph
        image: differenceFeaturedImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      satisfaction: homeSatisfaction {
        headline: satisfactionHeadline
        paragraph: satisfactionParagraph
      }
    }
    blog: allWpPost(limit: 1, sort: { order: DESC, fields: date }) {
      nodes {
        date
        excerpt
        title
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`

export default IndexPage
