import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw from 'twin.macro'
import { Link as AnchorLink, animateScroll as scroll } from 'react-scroll'

import { dateFormat } from '../helpers'

import { Button, ButtonA } from '../components/Button'
import { H1, H2 } from '../components/Headings'
import Hero from '../components/Hero'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import A from './A'
import PostExcerpt from './Post/Excerpt'

const __Hero = ({ data }) => {
  return (
    <Hero
      css={[
        tw`text-white bg-gradient-to-r from-blue-600 to-transparent mb-24`,
        'height: 600px;',
      ]}
      background={data.backgroundImage.localFile.childImageSharp.fixed}
    >
      <p tw="text-2xl" dangerouslySetInnerHTML={{ __html: data.crown }} />
      <H1
        display
        tw="mb-6 text-white leading-tight md:(leading-normal)"
        dangerouslySetInnerHTML={{ __html: data.headline }}
      />
      <ButtonA href={data.cta.url} large tw="mb-6">
        {data.cta.title} <FontAwesomeIcon icon={faChevronRight} tw="ml-8" />
      </ButtonA>
      <p>
        Or Call{' '}
        <a href="tel:8002399898" tw="underline font-bold">
          800-239-9898
        </a>
      </p>
      <PageDownButton />
    </Hero>
  )
}

const PageDownButton = () => (
  <AnchorLink
    to="services"
    smooth={true}
    duration={500}
    css={[
      tw`absolute h-16 w-16 bg-white text-yellow-600 rounded-full shadow-lg transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center cursor-pointer
            transition ease-in-out duration-150
            hover:(bg-yellow-600 text-white)
            focus:(bg-yellow-600 text-white outline-none shadow-outline)`,
      'left: 50%; top: 100%;',
    ]}
  >
    <FontAwesomeIcon icon={faChevronDown} size="2x" />
  </AnchorLink>
)

const Services = props => {
  const { data } = props
  return (
    <div id="services" {...props}>
      <H2>{data.headline}</H2>
      <p tw="mb-6">{data.paragraph}</p>
      <div tw="md:(grid grid-cols-2 col-gap-8 row-gap-8)">
        {data.services.map(service => (
          <a href={service.link.url} className="group" tw="block mb-8 md:(m-0)">
            <Img
              fluid={service.image.localFile.childImageSharp.fluid}
              tw="mb-2 transform shadow-none translate-y-0 group-hover:(-translate-y-2 shadow-lg) transition ease-in-out duration-200"
            />
            <div tw="font-condensed uppercase text-xl font-normal text-gray-900">
              {service.headline}{' '}
              <span
                css={[
                  tw`font-extrabold text-yellow-600 text-4xl leading-4 inline-block transform group-hover:(translate-x-2) transition ease-in-out duration-200`,
                  'position:relative; top:5px;',
                ]}
              >
                →
              </span>
            </div>
          </a>
        ))}
      </div>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </div>
  )
}

const Blog = props => {
  const {
    title,
    date,
    categories,
    featuredImage,
    excerpt,
    slug,
  } = props.data.nodes[0]

  return (
    <PostExcerpt
      title={title}
      date={date}
      categories={categories}
      featuredImage={featuredImage}
      excerpt={excerpt}
      slug={slug}
      {...props}
    />
  )
}

const Difference = props => {
  const { data } = props

  return (
    <div {...props}>
      <H2>{data.headline}</H2>
      <Img fluid={data.image.localFile.childImageSharp.fluid} tw="mb-4" />
      <p dangerouslySetInnerHTML={{ __html: data.paragraph }} />
    </div>
  )
}

const Satisfaction = props => {
  const { data } = props

  return (
    <div {...props}>
      <H2>{data.headline}</H2>
      <p dangerouslySetInnerHTML={{ __html: data.paragraph }} />
    </div>
  )
}

export default {
  Hero: __Hero,
  Services,
  Blog,
  Difference,
  Satisfaction,
}
